<template>
  <div>
    <VTitle :title="$t('app.learners', 2)" class="section-title" />

    <TableCard
      display-pagination
      :headers="headers"
      :is-loading="isLoading"
      :rows="displayedRows"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
    />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useCompetency from "@/composables/useCompetency";
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
import useTexts from "@/composables/useTexts";
// Components
import TableCard from "@/components/tables/TableCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    TableCard,
    VTitle
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    competency: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { endpoint } = useCompetency();
    const { data, pagination, isLoading, read } = useReadMultiple();
    const { getText } = useTexts();

    // Constants
    const headers = [
      {
        text: t("app.name"),
        value: "displayed_name"
      },
      {
        text: t("app.trainings"),
        value: "displayed_training"
      },
      {
        text: t("app.completed_on"),
        value: "displayed_completed_on"
      }
    ];

    // Computed
    const displayedRows = computed(() => {
      return data.value.map(x => ({
        ...x,
        displayed_name: `${x.registration.learner.firstname} ${x.registration.learner.lastname}`,
        displayed_training: getText(x.registration.training.texts, "name"),
        displayed_completed_on: x.completed_on
      }));
    });

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.learners", 2)} - ${t("app.competencies", 2)} - ${t("app.teaching")}`;

      const name = getText(props.competency?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: `${endpoint}.learners`,
        pathParams: {
          id: props.id
        }
      });
    };

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      headers,
      displayedRows,
      // useReadMultiple
      isLoading,
      pagination,
      // Pagination
      onUpdateCurrentPage
    };
  }
};
</script>
